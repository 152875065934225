.top_btns[data-v-2679330e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.top_btns .left_select[data-v-2679330e] {
  display: flex;
  align-items: center;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-2679330e] {
  color: white !important;
  width: 10px;
}
.div_p[data-v-2679330e] {
  line-height: 28px;
  border-bottom: 1px solid #eee;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.div_p p[data-v-2679330e] {
  display: inline-block;
  margin-right: 10px;
}
.div_p[data-v-2679330e]:last-child {
  border-bottom: none;
}
.item_icon[data-v-2679330e] {
  cursor: pointer;
}
.item_icon i[data-v-2679330e] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 2px;
}
.item_icon p[data-v-2679330e] {
  font-size: 13px;
  color: #333;
}
.icon-weixintuisong[data-v-2679330e] {
  color: #15ba11;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 2px;
}